import React, { useState, useEffect } from "react";
import dateFormat from "dateformat";

function signStringToSymbol(signString) {

    if (signString === "Minus") {
        return "-";
    }
    else if (signString === "Plus") {
        return "+";
    }

    return undefined;
}

function formatLineAsDiv(line) {
    var oddsSymbol = "X";
    var oddsSign = "X";
    var oddsValue = "X";
    var symbolColor = 'white';

    var lineElements = [];

    if (line.OverOdds != null && !line.HasMultiplierOdds) {
        oddsSign = signStringToSymbol(line.OverOdds.Sign);
        oddsValue = line.OverOdds.Value;
        lineElements.push(<div>{line.PlayerName} | <span style={{ color: '#66ff00' }} >O</span> {line.Value} {line.StatType} {oddsSign}{oddsValue} | {line.PlayerTeam} vs {line.OpponentTeam} | {line.Provider}</div>);
    }
    if (line.UnderOdds != null && !line.HasMultiplierOdds) {
        oddsSign = signStringToSymbol(line.UnderOdds.Sign);
        oddsValue = line.UnderOdds.Value;
        lineElements.push(<div>{line.PlayerName} | <span style={{ color: '#FF0000' }} >U</span> {line.Value} {line.StatType} {oddsSign}{oddsValue} | {line.PlayerTeam} vs {line.OpponentTeam} | {line.Provider}</div>);
    }

    return lineElements;
}

function GetNFLLineData(name) {

    var urlString = "http://api.bspete.com/GetStoredNFLLines?"
    
    if(name){
        urlString += ("&name=" + name)
    }

    return fetch(urlString, {
        method: "GET"
    })
        .then((response) => response.json())
        .then((data) => {
            console.log("data: " + data)
            return data;
        }).catch((e) => console.log(e));

}

function NFLLineSearch() {

    const [lineData, setLineData] = useState(null);
    const [lineDisplayElement, setLineDisplayElement] = useState(null);
    const [isRunningFunction, setIsRunningFunction] = useState(null);
    const [searchName, setSearchName] = useState(null);
    const [headerString, setHeaderString] = useState("Line Search");

    var retrievalDate = undefined;
    var updatedString = undefined;

    useEffect(() => {

        if (lineData) {

            var lines = lineData?.RawLines;

            var lineElements = lines?.map(formatLineAsDiv);

            lineElements = <div>{lineElements}</div>

            if (lineData.RetrievalTime != undefined) {
                retrievalDate = Date.parse(lineData.RetrievalTime);
                setHeaderString("Line Search updated on " + dateFormat(retrievalDate, "mmmm dS, h:MM:ss TT")) ;
            }

            setLineDisplayElement(<div>
                {lineElements}
            </div>);

        }
        else {
            setLineDisplayElement(<></>);
        }
    }, [lineData, isRunningFunction]);

    return (
        <div>
            <div>
                <h2>{headerString}</h2>
            </div>
            <div>
                <label>
                    Player Name <input type="text" name="filterInput" onChange={e => setSearchName(e.target.value)} />
                </label>
            </div>
            <br />
            <div>
                <button disabled={isRunningFunction} className="App-button" onClick={() => {
                    setIsRunningFunction(true);
                    GetNFLLineData(searchName)
                        .then(response => {
                            setLineData(response);
                            console.log(lineData)
                        })
                        .then(() => setIsRunningFunction(false))
                        .catch(() => setIsRunningFunction(false));
                }}>Search</button>
            </div>
            <br />
            {lineDisplayElement}
        </div>
    );
}

export default NFLLineSearch