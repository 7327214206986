import React from "react";
import { useNavigate } from "react-router-dom";

function NFLLanding() {

    const navigate = useNavigate()

    const goToMatches = () => {
        navigate("/nfl/matches");
    }

    const goToMismatches = () => {
        navigate("/nfl/mismatches");
    }

    const goToSearch = () => {
        navigate("/nfl/search");
    }

    var nflLandingPage = <div>

        <div className="App-header-center">
            <h1 >NFL</h1>
            <div>
                <button className="App-button" onClick={() => goToSearch()}>Search</button>
                <button className="App-button" onClick={() => goToMatches()}>Matches</button>
                <button className="App-button" onClick={() => goToMismatches()}>Mismatches</button>
            </div>
        </div>
    </div>


    return nflLandingPage;
}

export default NFLLanding