import React from "react";
import { useNavigate } from "react-router-dom";

function NBALanding() {

    const navigate = useNavigate()

    const goToMatches = () => {
        navigate("/nba/matches");
    }

    const goToMismatches = () => {
        navigate("/nba/mismatches");
    }

    var nbaLandingPage = <div>

        <div className="App-header-center">
            <h1 >NBA</h1>
            <div>
                <button className="App-button" onClick={() => goToMatches()}>Matches</button>
                <button className="App-button" onClick={() => goToMismatches()}>Mismatches</button>
            </div>
        </div>
    </div>


    return nbaLandingPage;
}

export default NBALanding